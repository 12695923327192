import PropTypes from "prop-types"
import Toggle from 'react-toggle'
import './styles.css'

export const Switcher = ({ label, value, name, onChange, title = null, ...props }) => {
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <label htmlFor={name} title={title}>{label}</label>
    <Toggle
      {...props}
      id={name}
      defaultChecked={value}
      checked={value}
      onChange={(e) => onChange(e?.target)}
    />
  </ div>
}

Switcher.propTypes = {
  label: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  title: PropTypes.any
}
