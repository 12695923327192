import React, { useCallback, useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import MapToHTML from 'components/map-to-html';
import OrderVolumeList from '../order-volume-list'
import OrderVolumeCards from '../order-volume-cards'
import OrderVolumeMessage from '../order-volume-message'
import OrderVolumeAccept from '../order-volume-accept'
import OrderVolumeFilter from '../order-volume-filter'
import Header from 'components/header'
import HintList from 'components/hint-list';
import Pagination from 'components/pagination'
import VisionOption from 'components/vision-option'
import { useOrderVolumeContext } from '../../context'
import { userFornecedor as isUserFornecedor} from 'services/permissions/getPermissions'
import { getOrderVolume } from '../../services/order-volume.service'
import { getVisionType } from 'utils/visionType'
import { formatNumber } from 'utils/format';
import * as S from './styled'

export const OrderVolume = () => {
  const [visionType, setVisionType] = useState(
    localStorage.getItem("visionTypeOrderVolume") || "Grid"
  )

  const {
    items,
    setItems,
    totalizer,
    setTotalizer,
    pagination,
    setPagination,
    filter,
    setOrderVolumeFilter,
    setLoading,
    checkbox,
    setCheckbox
  } = useOrderVolumeContext()

  const fetchData = useCallback(async (newFilter = filter) => {
    setLoading(true)
    setCheckbox([])

    try {
      const { Data, CurrentPage, TotalItems, TotalPages } = await getOrderVolume(newFilter)
      setItems(Data)
      setPagination(prevState => ({ ...prevState, CurrentPage, TotalItems, TotalPages }))
      if (isUserFornecedor()) setTotalizer(calculateVolumnTotalizer(Data))
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [filter])

  const calculateVolumnTotalizer = (data) => {
    const totalVolumn = data.reduce((n, {VolumeOrderQuantity}) => n + VolumeOrderQuantity, 0)
    return formatNumber(totalVolumn)
  }

  const paginate = (page) => {
    const newFilter = {
      ...filter,
      _page: page,
      _size: 50
    }
    setOrderVolumeFilter(newFilter)
  }

  const handleVisionType = (newVisionType) => {
    setVisionType(newVisionType)
    localStorage.setItem("visionTypeOrderVolume", newVisionType)
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <React.Fragment>
      <Header title={intl.get('commons.orderVolume')}>
        <S.Actions>
          <OrderVolumeFilter />
        </S.Actions>
      </Header>
      {items.length > 0 ?
        <React.Fragment>
          <S.Actions>
            {getVisionType(visionType, "List") &&
              <OrderVolumeAccept
                refresh={fetchData}
                volumeOrdersIds={checkbox}
                volumeOrders={items}
              />
            }
            <VisionOption
              visionType={visionType}
              changeVision={newVisionType => handleVisionType(newVisionType)} />
          </S.Actions>
          <S.Container>
            {getVisionType(visionType, "Grid") && <OrderVolumeCards data={items} />}
            {getVisionType(visionType, "List") && <OrderVolumeList data={items} />}
            <S.Footer>
              {
                isUserFornecedor() ?
                <S.Totalizer data-testid='total-volume'>
                  <HintList applyHover={false} itens={[`${totalizer}`]} >
                    <MapToHTML itens={[`${intl.get('commons.qtdTotal')}: ${totalizer}`]} />
                  </HintList>
                </S.Totalizer>
                : <div/>
              }
              <Pagination
                refreshSearch={paginate}
                page={pagination.CurrentPage}
                pageSize={pagination.size}
                amount={pagination.TotalItems} />
            </S.Footer>
          </S.Container>
        </React.Fragment>
        :
        <OrderVolumeMessage message={intl.get('commons.noRecordsFound')} />
      }
    </React.Fragment>
  )
}
