import React from 'react'
import { TIPOS_TAREFA } from 'pages/tasks-vision'
import icnTaskComplete from 'images/task-complete.svg'
import { Button } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal';
import ActionsInCancellation from '../../../timeline/em-cancelamento/actionsInCancellation';
import * as S from './styled'
import WaitingCheckRefusedButton from '../../../timeline/em-cancelamento/waitingCheckRefusedButton';
import { Link } from 'react-router-dom'
import TipoUsuario from 'models/usuarios/tipo-usuario';
import PropTypes from 'prop-types'

const PERFIL_MASTER_DATA_SOLUTIONS = 2

const ActionsCard = ({
  user,
  task,
  completarTarefa,
  startLoading,
  stopLoading,
  refresh,
  handleConfirmCancellationAwaitingCheck,
  handleDetailingCargoComposition
}) => {
  const renderAction = (tipoTarefa) => {
    switch (tipoTarefa) {
      case TIPOS_TAREFA.TAREFA_CANCELAMENTO_RECUSADO_AGUARDANDO_CIENCIA:
        return <WaitingCheckRefusedButton
          stopLoading={stopLoading}
          startLoading={startLoading}
          refresh={refresh}
          idPedido={task.IdOrigem}
        />
      case TIPOS_TAREFA.TAREFA_CANCELAMENTO_AGUARDANDO_CIENCIA:
        return <Button
          type="primary"
          value="OK"
          onClick={handleConfirmCancellationAwaitingCheck}
          className='btn-check-cancellation'
        />
      case TIPOS_TAREFA.TAREFA_AGUARDANDO_ANALISE_CANCELAMENTO:
        return <S.WaitingAnalysisButtom>
          <ActionsInCancellation
            stopLoading={stopLoading}
            startLoading={startLoading}
            refresh={refresh}
            idPedido={task.IdOrigem}
          />
        </S.WaitingAnalysisButtom>
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_CARGA_AGUARDANDO_REVISAO:
        return <Button
          type="primary"
          value={intl.get('tasks.review')}
          onClick={handleDetailingCargoComposition}
          className='btn-review-load-composition'
        />
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_NEGOCIACAO:
        return <Link
          style={{ textDecoration: 'none' }}
          to={`/loadComposition?code=${task.IdOrigem}`}
        >
          <Button
            type="primary"
            value={intl.get('tasks.review')}
            className={`btn-review-load-composition-${task.IdOrigem}`}
          />
        </Link>
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_RENEGOCIACAO_EMERGENCIAL:
        return user.TipoUsuario == TipoUsuario.Fornecedor ?
          <Button
            type="primary"
            value={intl.get('tasks.review')}
            onClick={handleDetailingCargoComposition}
            className='btn-review-load-composition'
          />
          : <Link
            style={{ textDecoration: 'none' }}
            to={`/loadComposition?code=${task.IdOrigem}`}
          >
            <Button
              type="primary"
              value={intl.get('tasks.review')}
              className='btn-review-load-composition'
            />
          </Link>
      case TIPOS_TAREFA.TAREFA_MASS_ARRIVAL_RISK:
        return (
          <div className='btns-complete-review'>
            <Button
              type="default"
              icon={icnTaskComplete}
              onClick={completarTarefa}
              className="btn-completar-tarefa"
            />

            <Link
              style={{ textDecoration: 'none' }}
              to={`/composicao`}
            >
              <Button
                type="primary"
                value={intl.get('tasks.review')}
                className='btn-review-load-composition'
              />
            </Link>
          </div>)
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_PARCIALMENTE_PREENCHIDA_AUTOPILOT:
      case TIPOS_TAREFA.TAREFA_VOLUME_PENDENTE_ACEITE:
      case TIPOS_TAREFA.TAREFA_VOLUME_CIENCIA_PENDENTE:
        return <Button
          type="default"
          icon={icnTaskComplete}
          onClick={completarTarefa}
          className="btn-completar-tarefa"
        />
      default:
        if (user.PerfilAcesso === PERFIL_MASTER_DATA_SOLUTIONS) {
          return <Button
            type="default"
            icon={icnTaskComplete}
            onClick={completarTarefa}
            className="btn-completar-tarefa"
          />
        }
    }
  }

  return (
    <div className="button-card" title={intl.get('tasks.completeTask') + task.Id}>
      {renderAction(task.TipoTarefa)}
    </div>
  )
}

ActionsCard.propTypes = {
  user: PropTypes.shape({
    PerfilAcesso: PropTypes.string.isRequired,
    TipoUsuario: PropTypes.array
  }).isRequired,
  task: PropTypes.shape({
    Id: PropTypes.number,
    IdOrigem: PropTypes.number.isRequired,
    TipoTarefa: PropTypes.number.isRequired,
  }).isRequired,
  completarTarefa: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  stopLoading: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  handleConfirmCancellationAwaitingCheck: PropTypes.func,
  handleDetailingCargoComposition: PropTypes.func,
};

export default ActionsCard;
