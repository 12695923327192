import { useState } from 'react'
import intl from 'react-intl-universal'
import { Button } from 'components/uikit-adapter/index'
import { Layout } from '@hbsis.uikit/react'
import { useOrderVolumeContext } from '../../context'
import { OrdersFilter as Filter } from 'components/orders-filter'
import { verifyIsFiltered } from 'utils/custom-functions'

import * as S from './styled'
import { IconFilterActive } from 'components/icons/icn-filter-active.icon'
import { IconFilterInactive } from 'components/icons/icn-filter-inactive.icon'

const OrderVolumeFilter = () => {
  const { setOrderVolumeFilter, filter } = useOrderVolumeContext()

  const [openFilter, setOpenFilter] = useState(false)
  const handleClose = () => setOpenFilter(false)

  const refresh = (filters) => {
    setOrderVolumeFilter({ ...filters })
    handleClose()
  }

  const getIconFilter = () => verifyIsFiltered(filter) ? <IconFilterActive fillColor='#55c9d8'  /> : <IconFilterInactive />;

  return (
    <S.Wrapper>
      <Button
        onClick={() => setOpenFilter(prevState => !prevState)}
        type='default'
        buttonText={intl.get('filters.filter')}
        buttonIcon={getIconFilter()}
      />

      <Layout.Sidebar width="450px"
        visible={openFilter}
        background="var(--color-contrast-white)"
        offsetTop="96px"
        side="right"
        block
        float
        triggerClose={() => setOpenFilter(false)}
      >
        <Filter
          handleSearch={refresh}
          filtersDefault={filter}
          isOrderVolume
        />
      </Layout.Sidebar>
    </S.Wrapper>
  )
}

export default OrderVolumeFilter
