import moment from 'moment';
import { getDaysByRange } from 'pages/load-composition/utils';

export const getFormattedHours = ({ horaInicial, horaFinal }) => {
  const format = 'HH:mm';
  const startTime = moment(horaInicial, format);
  const endTime = moment(horaFinal, format);
  const timeIntervals = [];

  while (startTime.isSameOrBefore(endTime)) {
    timeIntervals.push(startTime.format(format));
    startTime.add(15, 'minutes');
  }

  return timeIntervals;
}

export const getFormattedWeekday = () => {
  const dates = getDaysByRange('2021-12-13 00:00', '2021-12-19 00:00');
  return dates.map(m => moment(m).weekday())
}

export const optionsHours = () => [
  {
    hora: `${moment('00:00', "HH:mm").format("LT")} - ${moment('11:45', "HH:mm").format("LT")}`,
    key: 1 ,
    horaInicial: '00:00',
    horaFinal: '11:45'
  },
  { hora:
    `${moment('12:00', "HH:mm").format("LT")} - ${moment('23:45', "HH:mm").format("LT")}`,
    key: 2,
    horaInicial: '12:00',
    horaFinal: '23:45'
  }
]
