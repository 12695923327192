import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from 'components/uikit-adapter/index'

import TableRownColumnUnclickable from 'components/table-row-column-unclickable'
import DeleteIcon from 'images/icn-trash-bin.svg'
import ContractIcon from 'images/icn-contract.svg'
import IconButton from 'material-ui/IconButton'
import Pagination from 'components/pagination'
import intl from 'react-intl-universal'

const MaterialList = ({ data, currentPage, qtRegsPerPage, countData, refreshSearch, handleEdit, handleDelete, handleContracts, profileAdminOrMasterData, isFetching }) => (
  <Table
    fixedHeader
    fixedFooter
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%' }}
    bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: 'var(--color-contrast-white)' }}
    onCellClick={handleEdit}>
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      style={{ borderBottom: 'none' }}>
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn style={{ width: '15%' }}>{intl.get('commons.wallet')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '15%' }}>{intl.get('commons.family')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '25%' }}>{intl.get('commons.labeledFamily')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '25%' }}>{intl.get('commons.material')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '15%' }}>{intl.get('master-data.general.table.actions.editProvider.roundedUnity')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }}>{intl.get('commons.contracts')}
        </TableHeaderColumn>
        <TableHeaderColumn style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }} />
      </TableRow>
    </TableHeader>

    <TableBody
      displayRowCheckbox={false}
      showRowHover
    >
      {!isFetching && (!data || !data.length) &&
        <TableRow className="rowList">
          <TableRowColumn xs={12} md={12} lg={12} className="align-center">
            <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
          </TableRowColumn>
        </TableRow>
      }
      {!isFetching && data && !!data.length && data.map((item, i) => (
        <TableRow key={i} style={{ cursor: 'pointer' }}>
          <TableRowColumn style={{ width: '15%' }}>{item.NomeCarteira}</TableRowColumn>
          <TableRowColumn style={{ width: '15%' }}>{item.NomeFamilia}</TableRowColumn>
          <TableRowColumn style={{ width: '25%' }}>{item.NomeFamiliaRotulada}</TableRowColumn>
          <TableRowColumn style={{ width: '30%' }}>{`${item.Nome}`}</TableRowColumn>
          <TableRowColumn style={{ width: '15%' }}>{item.NomeUnidadeArredondamento}</TableRowColumn>
          <TableRownColumnUnclickable style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }}>
            <div title={intl.get('master-data.materials.actions.searchMaterial')} >
              <IconButton onClick={() => handleContracts(item.Id)} >
                <img src={ContractIcon} alt='contract-icon' />
              </IconButton>
            </div>
          </TableRownColumnUnclickable>
          {profileAdminOrMasterData &&
            <TableRownColumnUnclickable style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }}>
              <div title={intl.get('master-data.materials.actions.disableMaterial')} >
                <IconButton onClick={() => handleDelete(item.Numero)} >
                  <img src={DeleteIcon} alt='delete-icon' />
                </IconButton>
              </div>
            </TableRownColumnUnclickable>
          }
        </TableRow> 
      ))}
    </TableBody>
    <TableFooter>
      <Pagination
        page={currentPage}
        pageSize={qtRegsPerPage}
        amount={countData}
        refreshSearch={refreshSearch}
      />
    </TableFooter>
  </Table>
)

MaterialList.propTypes = {
  countData: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  data: PropTypes.shape({
    length: PropTypes.any,
    map: PropTypes.func
  }).isRequired,
  handleContracts: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  isFetching: PropTypes.any.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
  qtRegsPerPage: PropTypes.number.isRequired,
  refreshSearch: PropTypes.func.isRequired
}

export default MaterialList
