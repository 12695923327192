import PropTypes from "prop-types"
import { createContext, useContext, useMemo, useState } from 'react'
import Loading from 'components/center-loading'
import { volumeFiltersDefault } from './types/filter'
import storePersist from "utils/store-persist"

const OrderVolumeContext = createContext()

const OrderVolumeProvider = ({ children }) => {
  const [pagination, setPagination] = useState({
    CurrentPage: 0,
    TotalItems: 0,
    TotalPages: 0,
    size: 50
  })
  const [items, setItems] = useState([])
  const [totalizer, setTotalizer] = useState(0)
  const [checkbox, setCheckbox] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({
    ...storePersist.getValuesJSON("filtersOrderVolumeMulti") ?? volumeFiltersDefault,
    UseFilterDefault: JSON.parse(localStorage.getItem("filterMyItemsOnly"))
  })

  const setOrderVolumeFilter = (filter) => {
    setFilter(filter);
    storePersist.setValuesJSON("filtersOrderVolumeMulti", filter);
  }

  const values = useMemo(() => ({
    items,
    setItems,
    totalizer,
    setTotalizer,
    pagination,
    setPagination,
    filter,
    setFilter,
    checkbox,
    setCheckbox,
    loading,
    setLoading,
    setOrderVolumeFilter
  }), [items, totalizer, pagination, filter, checkbox, loading]);

  return (
    <OrderVolumeContext.Provider value={values}>
      {children}
      <Loading isLoading={loading} />
    </OrderVolumeContext.Provider>
  )
}

OrderVolumeProvider.propTypes = {
  children: PropTypes.any.isRequired
}

const useOrderVolumeContext = () => useContext(OrderVolumeContext)

export { OrderVolumeProvider, OrderVolumeContext, useOrderVolumeContext }
